import AccordionItem from '../../simple/accordion-item/accordion-item';
import Searchbox from '../../simple/searchbox/searchbox';
import FacetsList from '../../dynamic/facets-list/facets-list';
import TabbedContent from '../../dynamic/tabbed-content/tabbed-content';
import Nav from '../../dynamic/nav/nav';
import Header from '../../section/header/header';
import Content from '../../base/02-content/content';
import ResponsiveTables from '../../base/02-content/responsiveTable';

//global scope
//used for multiple instances of the same component
var globalClasses = {};
window.classes = globalClasses;

//used for class to class interactions
var mainClasses = {
  AccordionItem,
  Content,
  FacetsList,
  Header,
  Nav,
  ResponsiveTables,
  Searchbox,
  TabbedContent,
};

//used for class to class interactions
var mainClassesInstances = {};

function init() {
  for (const [key, value] of Object.entries(mainClasses)) {
    mainClassesInstances[key] = new value();
  }
}

document.addEventListener('DOMContentLoaded', init);

export default mainClasses;
