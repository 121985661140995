/* CLASS MANIPULATIONS */

/**
 * @param {Element} el
 * @param {string} cssClass
 * @returns success or failure
 */
export const addClass = (el, cssClass) => {
  if (!el.classList.contains(cssClass)) {
    el.classList.add(cssClass);
    return true;
  }

  return false;
};

/**
 * @param {Element} el
 * @param {string} cssClass
 * @returns success or failure
 */
export const removeClass = (el, cssClass) => {
  if (el.classList.contains(cssClass)) {
    el.classList.remove(cssClass);
    return true;
  }

  return false;
};

/**
 * Toggles class for an element node by utilizing other utility functions
 * @param {Element} el
 * @param {string} cssClass
 * @calls addClass || removeClass
 */
export const toggleClass = (el, cssClass) => {
  addClass(el, cssClass) || removeClass(el, cssClass);
};

/**
 * Toggles multiple classes for a single element node
 * @param {Element} el
 * @param {array} classList - list of strings
 * @calls addClass || removeClass
 */
export const toggleClasses = (el, classList) => {
  for (let c = 0; c < classList.length; c++) {
    addClass(el, classList[c]) || removeClass(el, classList[c]);
  }
};

/**
 * @param {Element} el - element to be wrapped
 * @param {Element} wrapper - wrapper node to be created to wrap around el
 * @param {string} wrapperClass - class(es) to add to the wrapper
 */
export const wrapElement = (el, wrapper, wrapperClass) => {
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
  addClass(wrapper, wrapperClass);
};

/**
 * Toggles a binary attribute of an element node
 * @param {Element} el
 * @param {string} attr - name of element's attribute to target
 * @returns success or failure
 */
export const toggleAttribute = (el, attr) => {
  let currentValue = el.getAttribute(attr);

  if (currentValue == 'true') {
    el.setAttribute(attr, 'false');
    return true;
  } else if (currentValue == 'false') {
    el.setAttribute(attr, 'true');
    return true;
  }

  return false;
};

/**
 * Creates a dynamically sized slide up/down effect for toggleable content.
 * Does not control open/close class to simplify cases with children focus keeping state open
 * @param {event} e - dom event that triggered for scope
 * @param {string} parentContainer - identifier for the parent container. (Eg: '.class')
 * @param {string} toggleContent - identifier for the content container to animate
 * @param {string} cssClass - class to look for whether container is open/closed. Default is 'is-open'
 */
export const slideDownTogglableSection = (
  e,
  parentContainer,
  toggleContent,
  cssClass = 'is-open'
) => {
  // get parent toggle-section to locate content & apply/remove open class
  let sectionContainer = e.target.closest(parentContainer);
  if (!sectionContainer) {
    return;
  }

  let contentContainer = sectionContainer.querySelector(toggleContent);
  if (!contentContainer) {
    return;
  }

  let startHeight = contentContainer.scrollHeight;

  if (sectionContainer.classList.contains(cssClass)) {
    // store transition for animation later
    var transitions = contentContainer.style.transition;
    contentContainer.style.transition = '';
    contentContainer.style.maxHeight = startHeight + 'px';

    // on next animation frame explicitly set height to its current pixel height,
    // to avoid transitioning out of 'auto'
    window.requestAnimationFrame(function () {
      contentContainer.style.transition = transitions;

      window.requestAnimationFrame(function () {
        contentContainer.style.maxHeight = 0 + 'px';
        contentContainer.style.removeProperty('max-height');
      });
    });
  } else {
    // expand
    contentContainer.style.maxHeight = startHeight + 'px';
  }
};
