import { removeClass, toggleClass } from '../../assets/js/utility';

class Searchbox {
  constructor() {
    this.activeClass = 'is-active';
    this.compactSearchboxes = document.querySelectorAll('.simple-searchbox.compact');

    if (this.compactSearchboxes) {
      this.compactSearchboxes.forEach((searchbox) => {
        let compactButton = searchbox.querySelector('.compact-button');
        let searchForm = searchbox.querySelector('form');

        if (compactButton && searchForm) {
          // show/hide form on button click
          compactButton.addEventListener('click', (e) => {
            let target = e.target.closest('.simple-searchbox');

            if (target) {
              toggleClass(target, this.activeClass);

              // automatically set focus on input
              let input = target.querySelector('.search-input');
              if (input) {
                input.focus();
              }
            }
          });

          // hide form if out of focus
          searchForm.addEventListener('focusout', (e) => {
            // do not proceed if bubbled event triggered before
            // active element set that is still in the form
            // eg: submit button focused or clicked
            if (searchForm.contains(e.relatedTarget)) {
              return;
            }

            let searchContainer = e.target.closest('.simple-searchbox');

            if (searchContainer) {
              removeClass(searchContainer, this.activeClass);
            }
          });
        }
      });
    }
  }
}

export default Searchbox;
