class Content {
  constructor() {
    this.linkedImages = document.querySelectorAll('a > img');

    if (this.linkedImages) {
      this.removeLinkBorder(this.linkedImages);
    }
  }

  /**
   * For all the images nested inside of an anchor tag on the page,
   * applies the 'no-decoration' class to the anchor element so that linked images
   * do not show any of the plain text link styling
   *
   * @param {nodeList} elementList
   */
  removeLinkBorder(elementList) {
    elementList.forEach((element) => {
      let parentLink = element.parentElement;

      if (!parentLink.classList.contains('no-decoration')) {
        parentLink.classList.add('no-decoration');
      }
    });
  }
}

export default Content;
