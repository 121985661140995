import {
  toggleClass,
  toggleAttribute,
  slideDownTogglableSection,
} from '../../assets/js/utility';

class AccordionItem {
  constructor() {
    this.toggleClass = 'is-open';
    this.accordionHeadings = document.querySelectorAll(
      '.simple-accordion-item .accordion-item-heading'
    );

    if (this.accordionHeadings) {
      this.accordionHeadings.forEach((heading) => {
        heading.addEventListener('click', (e) => {
          let accordionItem = e.target.closest('.simple-accordion-item');
          if (!accordionItem) {
            return;
          }

          slideDownTogglableSection(e, '.simple-accordion-item', '.content');
          toggleAttribute(heading, 'aria-expanded');
          toggleClass(accordionItem, this.toggleClass);
        });
      });
    }
  }
}

export default AccordionItem;
