import {
  addClass,
  removeClass,
  slideDownTogglableSection,
} from '../../assets/js/utility';

class TabbedContent {
  constructor() {
    this.tabbedContentContainerClass = '.dynamic-tabbed-content';
    this.tabLists = document.querySelectorAll(
      `${this.tabbedContentContainerClass} .tablist`
    );

    this.activeClass = 'is-active';
    this.overflowClass = 'is-overflowing';
    this.pageHash = '';

    // all # links on the page to be filtered down by relevant tabs
    this.relevantLinkHashes = [];
    this.hashAnchorElements = document.querySelectorAll('a[href^=\\#]');
    this.allLinkHashes = [];

    if (this.tabLists) {
      if (this.hashAnchorElements) {
        this.allLinkHashes = [...this.hashAnchorElements]
          .map(({ hash }) => hash)
          .filter((str) => str != ''); // don't want empties
      }

      this.InitTabbedContent();

      // check if tablist is overflowing after a window resize
      window.addEventListener('resize', () => {
        this.tabLists.forEach((list) => {
          this.checkOverflow(list);
        });
      });
    }
  }

  checkOverflow(el) {
    let hasOverflow = el.clientWidth < el.scrollWidth;

    if (hasOverflow) {
      addClass(el, this.overflowClass);
    } else {
      removeClass(el, this.overflowClass);
    }
  }

  /**
   * Sets active states on given tab & panel elements that are not
   * animated by the slideToggle script
   * @param {Node Element} tabElement
   * @param {Node Element} panelElement
   */
  setNonAnimatedTabAndPanel(tabElement, panelElement) {
    if (tabElement && panelElement) {
      tabElement.setAttribute('aria-selected', 'true');
      addClass(tabElement, this.activeClass);
      addClass(panelElement, this.activeClass);

      // set height for smooth slide animation if users click on tab
      let startHeight = panelElement.scrollHeight;
      panelElement.style.maxHeight = startHeight + 'px';
    }
  }

  /**
   * Sets the provided tab element & its corresponding content panel
   * to an active state
   * @param {event} e - (optional) if user clicks a tab
   * @param {Node Element} tabElement - tab heading element to set active
   * calls removeActiveStateOfPrevious
   */
  setSelectedTab(e = null, tabElement) {
    if (tabElement) {
      if (e) {
        // tab is clicked
        let correspondingContentPanel = document.querySelector(
          '#tabContent-' + tabElement.id
        );

        tabElement.setAttribute('aria-selected', 'true');
        addClass(tabElement, this.activeClass);
        addClass(correspondingContentPanel, this.activeClass);

        // open panel
        slideDownTogglableSection(
          e,
          this.tabbedContentContainerClass,
          `#tabContent-${tabElement.id}`,
          this.activeClass
        );
      } else {
        // # anchor clicked directing to tab,
        // won't have event context for panel
        let correspondingContentPanel = document.querySelector(
          '#tabContent-' + tabElement.id
        );

        this.setNonAnimatedTabAndPanel(tabElement, correspondingContentPanel);
      }

      // find old active tab & remove active state
      this.removeActiveStateOfPrevious(tabElement);
    }
  }

  /**
   * Called by SetSelectedTab. Removes active state of tab that does not match
   * the current tab element it is given
   * @param {Node Element} currentTabElement - tab heading element being set active
   */
  removeActiveStateOfPrevious(currentTabElement) {
    let tabList = currentTabElement ? currentTabElement.closest('.tablist') : null;
    let containingElement = currentTabElement
      ? currentTabElement.closest(this.tabbedContentContainerClass)
      : null;

    if (tabList && containingElement) {
      // check each sibling in the tablist for the previously active tab
      for (let i = 0; i < tabList.children.length; i++) {
        let tab = tabList.children[i];
        let tabId = tab.getAttribute('id');

        if (
          tabId != currentTabElement.id &&
          tab.classList.contains(this.activeClass)
        ) {
          // found the previously active tab
          let previousContentPanel = containingElement.querySelector(
            '#tabContent-' + tabId
          );

          tab.setAttribute('aria-selected', 'false');
          removeClass(tab, this.activeClass);

          // remove active settings from corresponding content panel
          if (previousContentPanel) {
            removeClass(previousContentPanel, this.activeClass);
            previousContentPanel.style.removeProperty('max-height');
          }
        }
      }
    }
  }

  /**
   * Calls setSelectedTab & scrollToTabContainer when a user clicks a tab
   * @param {event} e - user clicked on a tab heading
   */
  onTabClick(e) {
    let clickedTab = e.target.closest('.tab-heading'); // in case clicked on svg in button

    if (clickedTab) {
      // get content panels from same container
      let tabList = clickedTab.closest('.tablist');
      let containingElement = clickedTab.closest(this.tabbedContentContainerClass);

      if (containingElement && tabList) {
        let correspondingContentPanel = containingElement.querySelector(
          '#tabContent-' + clickedTab.id
        );

        if (correspondingContentPanel) {
          this.setSelectedTab(e, clickedTab);
        }

        // scroll back to top in case user partially scrolled into a content panel
        this.scrollToTabContainer(clickedTab);
      }
    }
  }

  /**
   * Given a tab, scrolls to its parent Tabbed Content container
   * @param {Node Element} tabElement - clicked/active tab
   */
  scrollToTabContainer(tabElement) {
    if (tabElement) {
      const VIEWHEIGHT = document.documentElement.clientHeight;
      const OFFSET = VIEWHEIGHT * 0.1;

      let containingElement = tabElement.closest(this.tabbedContentContainerClass);
      let elementTop = containingElement.getBoundingClientRect().top;

      // don't jump to if already in ~ top->center view
      if (elementTop >= 0 && elementTop <= (window.innerHeight || VIEWHEIGHT) / 2) {
        return;
      }

      window.scrollTo({
        behavior: 'smooth',
        top: elementTop - document.body.getBoundingClientRect().top - OFFSET,
      });
    }
  }

  InitTabbedContent() {
    let searchForPageHash = false;

    // check url for hash
    if (window.location.hash != '') {
      this.pageHash = window.location.hash.replace('#', '');
      searchForPageHash = true;
    }

    // Begin setting all tab list interactions on page
    this.tabLists.forEach((tabbedList) => {
      // tab headings
      let tabs = tabbedList.querySelectorAll('[role=tab]');
      let allTabIds = [...tabs].map(({ id }) => id);

      // check if any # links match a tab & add to relevant list
      if (this.allLinkHashes.length > 0) {
        allTabIds.filter((id) => {
          if (this.allLinkHashes.indexOf(`#${id}`) !== -1) {
            this.relevantLinkHashes.push(`#${id}`);
          }
        });
      }

      // get content panels from same container
      let containingElement = tabbedList.closest(this.tabbedContentContainerClass);
      let tabPanels = containingElement
        ? containingElement.querySelectorAll('[role=tabpanel]')
        : null;

      if (tabPanels) {
        // default init
        let initialTab = tabs[0];
        let initialPanel = tabPanels[0];

        // determine if init should be based on url #
        if (this.pageHash.length > 0 && searchForPageHash) {
          if (allTabIds.indexOf(this.pageHash) >= 0) {
            // found matching tab to direct to
            searchForPageHash = false;
            let initIndex = allTabIds.indexOf(this.pageHash);

            initialTab = tabs[initIndex];
            initialPanel = tabPanels[initIndex];

            // scroll to tabbed content
            this.scrollToTabContainer(initialTab);
          }
        }

        // Init 1st tab & content on load
        this.setNonAnimatedTabAndPanel(initialTab, initialPanel);

        // set listeners
        tabs.forEach((tab) => {
          tab.addEventListener('click', this.onTabClick.bind(this));
        });

        // pair down relevant anchor elements for listeners
        this.hashAnchorElements = [...this.hashAnchorElements].filter((element) => {
          if (this.relevantLinkHashes.indexOf(element.hash) !== -1) {
            return element;
          }
        });

        this.hashAnchorElements.forEach((anchor) => {
          anchor.addEventListener('click', (e) => {
            let targetHash = e.target.hash;
            let currentTabElement = document.querySelector(
              `${this.tabbedContentContainerClass} ${targetHash}`
            );

            e.preventDefault(); // prevent normal snap to element

            this.setSelectedTab(null, currentTabElement);

            // resume normal behavior but with smooth scroll
            window.location.hash = targetHash;
            this.scrollToTabContainer(currentTabElement);
          });
        });
      }

      // change glider behavior if there's overflow
      this.checkOverflow(tabbedList);
    });
  }
}

export default TabbedContent;
