import {
  slideDownTogglableSection,
  toggleClass,
  addClass,
  removeClass,
} from '../../assets/js/utility';

class FacetsList {
  constructor() {
    this.MOBILE_BREAKPOINT = 870;
    this.overflowClass = 'is-overflowing';

    this.groupContainers = document.querySelectorAll('.dynamic-facets-list .groups');
    this.toggleTriggers = document.querySelectorAll(
      '.dynamic-facets-list .group-heading'
    );
    this.mobileToggles = document.querySelectorAll(
      '.dynamic-facets-list .mobile-label'
    );

    if (this.toggleTriggers) {
      this.toggleTriggers.forEach((trigger) => {
        trigger.addEventListener('click', this.toggleGroup.bind(this));
      });
    }

    if (this.mobileToggles) {
      this.mobileToggles.forEach((toggle) => {
        toggle.addEventListener('click', this.toggleGroup.bind(this));
      });
    }

    if (this.groupContainers) {
      // check if tablist is overflowing after a window resize
      window.addEventListener('resize', () => {
        // apply overflow style if needed
        this.groupContainers.forEach((group) => this.checkOverflow(group));
      });
    }
  }

  checkOverflow(el) {
    let hasOverflow = el.clientHeight < el.scrollHeight;

    if (hasOverflow && window.innerWidth < this.MOBILE_BREAKPOINT) {
      addClass(el, this.overflowClass);
    } else {
      removeClass(el, this.overflowClass);
    }
  }

  toggleGroup(e) {
    e.preventDefault();
    let triggerElement = e.target;

    // on mobile do not want sliding while mimicking dropdown select
    if (window.innerWidth >= this.MOBILE_BREAKPOINT) {
      let toggledGroup = triggerElement.closest('.facet-group');

      if (triggerElement && toggledGroup) {
        slideDownTogglableSection(e, '.facet-group', '.facet-list');
        toggleClass(toggledGroup, 'is-open');
      }
    } else {
      let toggledContainer = triggerElement.closest('.dynamic-facets-list');

      // no animation
      if (toggledContainer) {
        toggleClass(toggledContainer, 'is-open');

        // apply overflow style if needed
        let groupContainer = toggledContainer.querySelector('.groups');

        if (groupContainer) {
          this.checkOverflow(groupContainer);
        }
      }
    }
  }
}

export default FacetsList;
