import {
  addClass,
  toggleClass,
  slideDownTogglableSection,
} from '../../assets/js/utility';

class Nav {
  constructor() {
    this.toggleClass = 'is-open';
    this.linksWithDropdown = document.querySelectorAll('.dynamic-nav .has-sublinks');
    this.activeLink = document.querySelector('.dynamic-nav .is-active');

    // set dropdown show/hide listener
    if (this.linksWithDropdown) {
      // for preventing duplicate event triggers
      let navActive = false;
      let allowArrowDownTrigger = false;

      for (let i = 0; i < this.linksWithDropdown.length; i++) {
        // mouse events
        this.linksWithDropdown[i].addEventListener('mouseenter', (e) => {
          if (navActive == false) {
            navActive = true;

            this.changeOpenState(e, this.linksWithDropdown[i], 'true');

            // in case triggered by an arrow down click
            // don't allow trigger from same event
            setTimeout(function () {
              allowArrowDownTrigger = true;
            }, 600);
          }
        });

        this.linksWithDropdown[i].addEventListener('mouseleave', (e) => {
          if (navActive == true) {
            navActive = false;
            this.changeOpenState(e, this.linksWithDropdown[i]);

            // prevent arrow click triggers until next mouseenter
            allowArrowDownTrigger = false;
          }
        });

        // keyboard events
        this.linksWithDropdown[i].addEventListener('focusin', (e) => {
          // stay open if focusing in submenu
          if (
            this.linksWithDropdown[i].contains(e.relatedTarget) ||
            navActive === true
          ) {
            return;
          }

          navActive = true;
          this.changeOpenState(e, this.linksWithDropdown[i], 'true');
        });

        this.linksWithDropdown[i].addEventListener('focusout', (e) => {
          // stay open if focusing in submenu
          if (
            this.linksWithDropdown[i].contains(e.relatedTarget) ||
            navActive === false
          ) {
            return;
          }

          navActive = false;
          this.changeOpenState(e, this.linksWithDropdown[i]);
        });

        // for mobile
        let downArrow = this.linksWithDropdown[i].querySelector('.arrow-down');

        if (downArrow) {
          downArrow.addEventListener('mousedown', (e) => {
            if (allowArrowDownTrigger == true) {
              // if not open, tell changeState to open it
              let openSubmenu =
                !this.linksWithDropdown[i].classList.contains('is-open');

              this.changeOpenState(
                e,
                this.linksWithDropdown[i],
                openSubmenu ? 'true' : 'false'
              );
            }
          });
        }
      }
    }

    // if dropdown item active, indicate on 1st level nav
    if (this.activeDropLink) {
      let parentItem = this.activeDropLink.closest('.has-sublinks');

      if (parentItem) {
        addClass(parentItem, 'is-active');
      }
    }
  }

  /**
   * Checks if submenu needs to be opened or not & sets the state if so
   * @param {Event} e - event trigger for animation
   * @param {Node Element} group - nav item (li) element
   * @param {string} makeExpanded - aria-expanded value to set
   * @param {string} toggleClass - class to toggle on the group element
   */
  changeOpenState(e, group, makeExpanded = 'false') {
    let linkElement = group.querySelector('.nav-link');
    let currentState = 'false';

    if (linkElement) {
      currentState = linkElement.getAttribute('aria-expanded');

      if (currentState != makeExpanded) {
        linkElement.setAttribute('aria-expanded', makeExpanded);

        // can't go outside if-statement cause must happen before class change
        slideDownTogglableSection(e, '.has-sublinks', '.submenu');
        toggleClass(group, this.toggleClass);
      }
    }
  }
}

export default Nav;
