import {
  addClass,
  removeClass,
  toggleClass,
  toggleAttribute,
} from '../../assets/js/utility';

class Header {
  constructor() {
    this.header = document.querySelector('.site-header');
    this.menuIcon = document.querySelector('#mobile-menu-icon');
    this.siteSearch = document.querySelector('.site-header .simple-searchbox');
    this.activeClass = 'is-active';

    this.cookieName = 'MHC_audience.activeType';
    this.cookieDefaultValue = 'GeneralAudience';
    this.audienceList = document.querySelector('.site-header .type-list');
    this.personalizedMenus = document.querySelectorAll(
      '.site-header .personalized-menu'
    );
    this.audienceOptionButtons = [];
    this.audienceOptionIds = []; // ids set by C# view based on cleaned button labels

    if (this.header) {
      if (this.siteSearch) {
        this.changeSearchboxVariant();

        window.addEventListener('resize', () => this.changeSearchboxVariant());
      }

      if (this.audienceList && this.personalizedMenus) {
        this.audienceOptionButtons = this.audienceList.querySelectorAll('button');

        if (this.audienceOptionButtons.length > 0) {
          this.audienceOptionButtons.forEach((option) => {
            // populate for quality checks
            this.audienceOptionIds.push(option.id);

            option.addEventListener('click', (e) => {
              this.setAudienceCookie(e.target.id);
              this.setActiveAudienceFocus(e.target);
            });
          });
        }

        // check if cookie exists already
        // if not, set to generic, else init labels
        let initCookie = this.readAudienceCookie();
        if (!initCookie || !this.audienceOptionIds.includes(initCookie)) {
          initCookie = this.cookieDefaultValue;
          this.setAudienceCookie(initCookie);
        }

        // get initial audience focus
        let initAudienceOption = this.audienceList.querySelector(`#${initCookie}`);
        this.setActiveAudienceFocus(initAudienceOption, false);
      }
    }

    if (this.menuIcon) {
      this.menuIcon.addEventListener('click', () => {
        toggleClass(this.menuIcon, 'is-open');
        toggleAttribute(this.menuIcon, 'aria-expanded');
      });

      // if a click made outside of the header area & menu open, close it
      document.querySelector('body').addEventListener('click', (e) => {
        if (
          this.menuIcon.classList.contains('is-open') &&
          !this.header.contains(e.target)
        ) {
          removeClass(this.menuIcon, 'is-open');
          toggleAttribute(this.menuIcon, 'aria-expanded');
        }
      });
    }
  }

  /**
   * adds or removes the compact variant class from the searchbox
   * depending on the window size for switching between mobile & not
   */
  changeSearchboxVariant() {
    const MOBILE_BREAKPOINT = 1235; // should match scss breakpoint

    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      removeClass(this.siteSearch, 'compact');
    } else {
      addClass(this.siteSearch, 'compact');
    }
  }

  /**
   * Sets all audience focus labels and states
   * @param {event} e - click event on button
   * @param {boolean} animate - set false if menu should not open on this call
   */
  setActiveAudienceFocus(el, animate = true) {
    // remove all previously pressed buttons then set new target
    this.audienceOptionButtons.forEach((option) => {
      let wrapperItem = option.closest('.audience-option');

      if (option === el) {
        addClass(wrapperItem, this.activeClass);
        option.setAttribute('aria-pressed', 'true');
      } else {
        removeClass(wrapperItem, this.activeClass);
        option.setAttribute('aria-pressed', 'false');
      }
    });

    // update labels
    let audienceNavItem = el.closest('li.focus-audience');
    let focusNavLinks = audienceNavItem.querySelectorAll('.drop-row .nav-link');
    let activeCookie = this.readAudienceCookie();

    if (audienceNavItem) {
      // remove previous active & set new active
      focusNavLinks.forEach((link) => {
        if (link.id.includes(activeCookie)) {
          addClass(link, this.activeClass);
        } else {
          removeClass(link, this.activeClass);
        }
      });

      let activePersonalizedLabel = audienceNavItem.querySelector(
        '.personalized-audience'
      );

      if (activePersonalizedLabel) {
        if (el.id === this.cookieDefaultValue) {
          activePersonalizedLabel.innerText = el.dataset.defaultPrompt;
          addClass(activePersonalizedLabel, 'is-default');
        } else {
          activePersonalizedLabel.innerText = el.innerText;
          removeClass(activePersonalizedLabel, 'is-default');
        }
      }

      // unset animated max-height for submenu changes
      if (animate) {
        let submenu = audienceNavItem.querySelector('.submenu');
        submenu.style.maxHeight = 'unset';
      }

      // open/close appropriate menus
      let activeButtonId = el.id;

      if (activeButtonId) {
        this.personalizedMenus.forEach((menu) => {
          if (`menuFor${activeButtonId}` === menu.id) {
            addClass(menu, 'is-open');
            menu.setAttribute('aria-hidden', false);
          } else {
            removeClass(menu, 'is-open');
            menu.setAttribute('aria-hidden', true);
          }
        });
      }
    }
  }

  /**
   * Sets the value of a cookie
   * @param {String} value - Value to set activeAudienceType cookie
   */
  setAudienceCookie(value) {
    const cookiePath = 'path=/;';
    const cookieAge = `max-age=${60 * 60 * 24 * 365};`;
    const cookieStrict = 'same-site=strict;';

    if (!value) {
      // assign non-specified/generic/undisclosed value
      value = this.cookieDefaultValue;
    }

    // create or update cookie value
    document.cookie = `${this.cookieName}=${value};${
      cookiePath + cookieAge + cookieStrict
    }`;
  }

  /**
   * Get the value of a cookie
   * Source: https://gist.github.com/wpsmith/6cf23551dd140fb72ae7
   * @return {String} - The activeAudienceType cookie value
   */
  readAudienceCookie() {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; ${this.cookieName}=`);

    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }
}
export default Header;
